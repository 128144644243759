@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=DM+Serif+Text:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400&display=swap"); */

html {
  font-size: 16px;
}

body {
  background-color: white;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  line-height: 1.45;
  color: #333;
}

p {
  margin-bottom: 1.25em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  line-height: 1.15;
  color: #555;
}

h1 {
  margin-top: 0;
  font-weight: 300;
  font-size: 2.488em;
}

h2 {
  font-weight: 400;
  font-size: 2.074em;
}

h3 {
  font-weight: 400;
  font-size: 1.728em;
}

h4 {
  font-size: 1.44em;
}

h5 {
  font-size: 1.2em;
}

small,
.text_small {
  font-size: 0.833em;
}

a {
  text-decoration: none;
}

a:link {
  color: #333333;
}

a:visited,
a:hover {
  color: #888888;
}

strong {
  font-weight: 500;
}

